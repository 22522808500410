<template>
	<v-text-field
		:id="id"
		ref="priceInput"
		:label="label"
		:loading="loading"
		:disabled="disabled"
		:hide-details="hideDetails"
		outlined
		:rules="rules"
		:reverse="reverse"
		class="pt-0"
		:class="[!hideTopMargin ? 'mt-3' : '']"
		v-model.number="priceinput"
		:prepend-inner-icon="reverse ? '' : 'mdi-currency-usd'"
		:append-icon="reverse ? 'mdi-currency-usd' : ''"
		type="number"
		maxlength="13"
		step="01"
		min="0"
		size="13"
		:hide-spin-buttons="hideSpinButtons"
		:append-outer-icon="appendOuterIcon"
		:placeholder="placeholder"
		:readonly="readonly"
		v-on:click:clear="$emit('click:clear', true)"
		v-on:click:append-outer="$emit('click:append-outer', true)"
		v-on:change="$emit('change', true)"
		v-on:keypress="limitDecimal1($event, priceinput), $emit('keypress', $event)"
		v-on:keyup="$emit('keyup', $event)"
		v-on:keydown="$emit('keydown', $event)"
		v-on:click="$emit('click', $event)"
		v-on:blur="$emit('blur', priceinput)"
		@paste="$emit('paste', $event)"
	></v-text-field>
</template>
<script>
export default {
	name: "price-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [String, Number],
			default: null,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		hideSpinButtons: {
			type: Boolean,
			default: false,
		},
		appendOuterIcon: {
			type: String,
			default: null,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		reverse: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		increaseFontSize: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			priceinput: null,
		};
	},
	watch: {
		value() {
			this.priceinput = this.value;
		},
		priceinput() {
			this.$emit("input", this.priceinput);
		},
	},
	mounted() {
		this.priceinput = this.value;
	},
};
</script>
