<template>
	<v-text-field
		:id="id"
		:ref="inputRef"
		:label="label"
		:loading="loading"
		:type="type"
		:disabled="disabled"
		:clearable="clearable"
		:readonly="readonly"
		:prefix="prefix"
		:suffix="suffix"
		:hide-details="hideDetails"
		:hide-spin-buttons="hideSpinButtons"
		outlined
		:rules="rules"
		:autofocus="autofocus"
		v-mask="vMask"
		class="pt-0"
		:class="{ ...customClass, 'mt-3': !hideTopMargin }"
		v-model.trim="textinput"
		:append-outer-icon="appendOuterIcon"
		:append-icon="appendIcon"
		:prepend-inner-icon="prependInnerIcon"
		:placeholder="placeholder"
		v-on:click:clear="$emit('click:clear', true)"
		v-on:click:append-outer="$emit('click:append-outer', true)"
		v-on:change="$emit('change', true)"
		v-on:keyup="$emit('keyup', true)"
		v-on:keydown="$emit('keydown', $event)"
		v-on:click="$emit('click', true)"
		v-on:blur="$emit('blur', textinput)"
		v-on:keypress="$emit('keypress', $event)"
		v-on:focusout="$emit('focusout')"
		v-on:focusin="$emit('focusin', $event)"
		v-on:focus="$emit('focus', $event)"
		v-bind="vBindAttrs"
		:reverse="reverse"
		v-on="vOn"
	></v-text-field>
</template>
<script>
export default {
	name: "text-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [String, Number],
			default: null,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		placeholder: {
			type: String,
			default: null,
		},
		prefix: {
			type: String,
			default: null,
		},
		suffix: {
			type: String,
			default: null,
		},
		inputRef: {
			type: String,
			default: "textInput",
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		vBindAttrs: {
			type: Object,
			default: () => {
				return {};
			},
		},
		vOn: {
			type: Object,
			default: () => {
				return {};
			},
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		hideSpinButtons: {
			type: Boolean,
			default: false,
		},
		appendOuterIcon: {
			type: String,
			default: null,
		},
		appendIcon: {
			type: String,
			default: null,
		},
		prependInnerIcon: {
			type: String,
			default: null,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: "text",
		},
		customClass: {
			type: String,
			default: "",
		},
		vMask: {
			type: String,
			default: "",
		},
		reverse: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			textinput: null,
		};
	},
	watch: {
		value() {
			this.textinput = this.value;
		},
		textinput() {
			this.$emit("input", this.textinput);
		},
	},
	mounted() {
		this.textinput = this.value;
	},
};
</script>
